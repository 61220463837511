import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { Col } from '@mapped/rivet/dist/mapped/responsive/col'
import { Row } from '@mapped/rivet/dist/mapped/responsive/row'
import { classnames } from '@mapped/rivet/dist/mapped/utils/classnames'
import { Check, Close } from '@mapped/rivet/dist/mui/icons'
import {
  Button,
  CircularProgress,
  IconButton,
} from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { FunctionComponent, useContext, useState } from 'react'
import {
  BillingContext,
  EPlanSlug,
  getPriceString,
  ProductDetails,
} from '../../contexts/billing'
import { useNotifications } from '../../hooks/useNotifications'
import { ECookieName } from '../../types/general'
import { removeCookie } from '../../utils/cookies'
import { BillingInfoForm, IBillingInfoFormValues } from './billingInfoForm'

export const SubscriptionModal: FunctionComponent<ISubscriptionModalProps> = (
  props
) => {
  const {
    plan,
    billingInfo,
    updateBillingInfo,
    updateSubscription,
    // setTermLength,
    // termLength,
    setPlan,
    subscriptionPlan,
  } = useContext(BillingContext)

  const notifications = useNotifications()

  const [isLoading, setIsLoading] = useState(false)

  const { title, description } = getHeaderTexts()

  function onRequestClose() {
    removeCookie(ECookieName.WISHING_PLAN)

    props.onClose()
  }

  function getHeaderTexts() {
    switch (subscriptionPlan) {
      case EPlanSlug.STARTER:
        return {
          title: 'Choose your plan',
          description: 'Choose your term length, number of building.',
        }
      case EPlanSlug.BASIC:
        return {
          title: 'Go Pro!',
          description:
            'Upgrade to the Pro plan and get all the benefits of it...',
        }
      case EPlanSlug.PRO:
        return {
          title: 'Your Pro Plan Details',
        }
    }
  }

  async function onSubmit(values: IBillingInfoFormValues) {
    setIsLoading(true)

    if (!(await updateBillingInfo(values))) {
      notifications.push({
        type: 'error',
        message: 'Whoops! Something went wrong...',
      })

      return setIsLoading(false)
    }

    if (!(await updateSubscription())) {
      notifications.push({
        type: 'error',
        message: 'Whoops! Something went wrong...',
      })

      return setIsLoading(false)
    }

    notifications.push({
      type: 'success',
      message: `You're now subscribed to the ${
        ProductDetails[plan!]?.title
      } plan!`,
    })

    onRequestClose()
    setPlan(undefined)
  }

  return (
    <MappedModal
      onClose={isLoading ? () => {} : onRequestClose}
      open={true}
      style={{
        width: 900,
        margin: '60px auto',
        alignSelf: 'flex-start',
      }}
    >
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>

        <div>
          {/* <Basics>
            <div className="term-length">
              <label>Term length</label>

              <Select
                color="primary"
                variant="outlined"
                value={termLength}
                onChange={(e) => setTermLength(e.target.value as any)}
              >
                <MenuItem value={ETermLength.MONTH}>Monthly</MenuItem>
                <MenuItem value={ETermLength.YEAR} disabled={true}>
                  Yearly (soon)
                </MenuItem>
              </Select>
            </div>

            <QuantitySelector />
          </Basics> */}

          <Plans>
            <Plan slug={EPlanSlug.PRO} />
          </Plans>

          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p style={{ fontSize: 16 }}>
              * Additional API usage fee applies to consumed normalized data
              (waived during Beta). Includes up to 100GB of normalized data or
              up-to 6 months of time series data; whichever is lower. All prices
              listed in USD.
            </p>

            <p style={{ fontSize: 18, marginTop: 15 }}>
              Visit <a href="https://mapped.com/pricing">mapped.com/pricing</a>{' '}
              for full details & FAQs
            </p>
          </div>
        </div>

        {!!plan && (
          <div id="payment" style={{ width: '100%', marginTop: 100 }}>
            <Row>
              <Col xs={12}>
                <BillingInfoForm
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  billingInfo={billingInfo}
                  onCancel={onRequestClose}
                  submitButtonText="Place my order"
                  style={{ paddingBottom: 0 }}
                />
              </Col>
              {/* <Col xs={4}>
                <PaymentSummary />
              </Col> */}
            </Row>
          </div>
        )}
      </Container>

      <IconButton
        style={{ position: 'absolute', top: '15px', right: '15px' }}
        size="large"
        onClick={onRequestClose}
        disableRipple={true}
      >
        <Close />
      </IconButton>
    </MappedModal>
  )
}

// const QuantitySelector: FunctionComponent = () => {
//   const {
//     buildingQuantity,
//     setBuildingQuantity,
//     isFetchingCalculation,
//     subscriptionPlan,
//     subscriptionBuildingQuantity,
//   } = useContext(BillingContext)

//   const [inputValue, setInputValue] = useState(buildingQuantity)

//   const isStarterPlan = subscriptionPlan === EPlanSlug.STARTER

//   useEffect(() => {
//     if (!isStarterPlan) {
//       setBuildingQuantity(subscriptionBuildingQuantity)
//     }
//   }, [isStarterPlan])

//   if (!isStarterPlan) {
//     return (
//       <QuantitySelectionContainer style={{ textAlign: 'right' }}>
//         <h2 className="subscription-building-quantity">
//           You currently have {pluralize('building', buildingQuantity, true)}
//         </h2>

//         <a
//           className="pricing-page-link"
//           href="https://www.mapped.com/pricing"
//           target="_blank"
//         >
//           See pricing for more
//         </a>
//       </QuantitySelectionContainer>
//     )
//   }

//   return (
//     <QuantitySelectionContainer>
//       <div>
//         <span>How many buildings do you have?</span>

//         <TextField
//           type="number"
//           inputMode="numeric"
//           size="small"
//           inputProps={{ min: 1, max: 1000 }}
//           value={inputValue}
//           onChange={(e) => setInputValue(e.target.value as any)}
//           onBlur={(e) => {
//             let value = Number(e.target.value)

//             if (!value) {
//               value = 1
//             }

//             setInputValue(value)
//             setBuildingQuantity(Number(value))
//           }}
//         />
//       </div>

//       <Slider
//         disabled={isFetchingCalculation}
//         value={Number(inputValue) || 1}
//         min={1}
//         max={1000}
//         onChange={(_, val) => setInputValue(Number(val))}
//         onChangeCommitted={(_, val) => setBuildingQuantity(Number(val))}
//         sx={{
//           '&': {
//             height: 6,
//           },
//           '& .MuiSlider-thumb': {
//             width: 25,
//             height: 25,
//           },
//         }}
//       />
//     </QuantitySelectionContainer>
//   )
// }

const Plan: FunctionComponent<IPlan> = ({ slug }) => {
  const {
    plan,
    setPlan,
    getPlanPrice,
    termLength,
    isFetchingCalculation,
    subscriptionPlan,
  } = useContext(BillingContext)

  const { title, description } = ProductDetails[slug] || {}

  const isSelected = plan === slug

  function renderPrice() {
    return (
      <>
        {isFetchingCalculation ? (
          <LoadingIndicator size={40} height={82.5} />
        ) : (
          <h3 className="price">
            {getPriceString(getPlanPrice(slug), true) || '--'}
          </h3>
        )}

        <p
          className="term-length"
          style={{
            opacity: plan === EPlanSlug.STARTER ? 0 : 1,
          }}
        >
          per building/{termLength}
        </p>
      </>
    )
  }

  function renderButton() {
    if (isSelected) {
      return (
        <div className="checkmark">
          <Check />
        </div>
      )
    }

    const isCurrent = subscriptionPlan === slug

    return (
      <Button
        color="primary"
        variant="contained"
        disabled={isFetchingCalculation || isCurrent}
        onClick={() => {
          setPlan(slug)

          setTimeout(() => {
            document
              .querySelector('#payment')
              ?.scrollIntoView({ behavior: 'smooth' })
          }, 300)
        }}
      >
        {isCurrent ? 'Current Plan' : `Upgrade to ${title}`}
      </Button>
    )
  }

  return (
    <PlanCard
      className={classnames({ 'plan-card': true, selected: isSelected })}
    >
      <h2 className="title">{title}</h2>
      <div className="middle">
        {renderPrice()}
        {renderButton()}
      </div>
      {!!description && <p className="text">{description}</p>}
    </PlanCard>
  )
}

const LoadingIndicator: FunctionComponent<{ size: number; height: number }> = ({
  height,
  size,
}) => {
  return (
    <div style={{ height, display: 'flex', alignItems: 'center' }}>
      <CircularProgress
        size={size}
        disableShrink={true}
        thickness={6}
        color={'text' as any}
      />
    </div>
  )
}

// const PaymentSummary: FunctionComponent = () => {
//   const { buildingQuantity, termLength, plan, getPlanPrice } =
//     useContext(BillingContext)

//   function getTotal() {
//     if (!plan) {
//       return 0
//     }

//     return getPlanPrice(plan) * buildingQuantity
//   }

//   return (
//     <PaymentSummaryContainer>
//       <h2 className="title">Summary</h2>

//       {!!plan && (
//         <div className="item">
//           <div className="info">
//             <strong>{ProductDetails[plan].title} plan</strong>

//             <p>
//               <span>{pluralize('Building', buildingQuantity, true)}</span>
//               {', '}
//               <span>{capitalize(termLength + 'ly')}</span>
//             </p>
//           </div>

//           <div className="price">
//             {getPriceString(getPlanPrice(plan) * buildingQuantity)}
//           </div>
//         </div>
//       )}

//       <h3 className="total">
//         <span style={{ flex: 1 }}>Total</span> {getPriceString(getTotal())}
//       </h3>
//     </PaymentSummaryContainer>
//   )
// }

const Container = styled.div`
  padding: 50px;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 25px;
  text-align: center;
`

const Description = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 75px;
  text-align: center;
`

const Card = styled.div`
  background: ${(props) => props.theme.palette.background.paper};
  padding: 30px;

  .title {
    font-size: 38px;
    font-weight: 700;
    margin: 0;
  }

  .text {
    font-size: 16px;
  }
`

// const Basics = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 50px;

//   .term-length {
//     display: flex;
//     margin-right: 9.5%;
//     border: 2px solid ${(props) => props.theme.palette.primary.main};

//     .MuiSelect-root {
//       * {
//         border: none !important;
//       }

//       svg {
//         color: ${(props) => props.theme.palette.primary.main};
//       }
//     }

//     > label {
//       justify-content: center;
//       align-items: center;
//       display: flex;
//       padding: 0 30px;
//       color: ${(props) => props.theme.palette.primary.main};
//       font-weight: bold;
//       border-right: 2px solid;
//       margin: 8px 0px 8px 0;
//     }

//     .MuiSelect-root {
//       flex: 1;

//       .MuiSelect-select {
//         padding-left: 30px;
//       }
//     }
//   }
// `

// const QuantitySelectionContainer = styled.div`
//   flex: 1;

//   > div:first-of-type {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: 5px;

//     span {
//       font-size: 18px;
//       margin-right: 15px;
//     }
//   }

//   .subscription-building-quantity {
//     font-size: 22px;
//     line-height: 28px;
//     margin: 0;
//     margin-bottom: 5px;
//   }

//   .pricing-page-link {
//     font-size: 16px;
//     font-weight: 500;
//     color: ${(props) => props.theme.palette.primary.main};

//     &:hover {
//       text-decoration: underline;
//     }
//   }
// `

const PlanCard = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 48%;
  border: 5px solid rgba(0, 0, 0, 0);

  &.selected {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  .middle {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .price {
    font-size: 55px;
    font-weight: 700;
    margin: 0;

    &.words {
      font-size: 28px;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  .term-length {
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.palette.text.a50};
  }

  button {
    margin: 40px 0 0;
  }

  .checkmark {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-top: 37.5px;
    color: ${(props) => props.theme.palette.primary.contrastText};
    background: ${(props) => props.theme.palette.primary.main};
  }
`

const Plans = styled.div`
  .plan-card {
    width: 100%;
  }
`

// const PaymentSummaryContainer = styled.div`
//   border-left: 1px solid ${(props) => props.theme.palette.divider};
//   height: 100%;
//   padding-left: 50px;
//   padding-top: 30px;
//   padding-bottom: 30px;

//   h2.title {
//     font-size: 32px;
//     margin: 0;
//     margin-bottom: 30px;
//   }

//   .item {
//     display: flex;

//     &:not(:first-of-type) {
//       margin-top: 40px;
//     }

//     .info {
//       flex: 1;
//       line-height: 28px;

//       p {
//         margin: 0;
//         font-size: 16px;
//       }

//       strong {
//         display: block;
//         font-size: 18px;
//         margin-bottom: 5px;
//       }
//     }

//     .price {
//       font-weight: bold;
//       margin-left: 30px;
//       font-size: 18px;
//       line-height: 28px;
//     }
//   }

//   .total {
//     font-size: 22px;
//     font-weight: 500;
//     display: flex;
//     margin-top: 60px;
//     color: ${(props) => props.theme.palette.primary.main};
//   }
// `

interface ISubscriptionModalProps extends MappedModalProps {}

interface IPlan {
  slug: EPlanSlug
}
