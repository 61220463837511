import { createContext, FunctionComponent, useState } from 'react'

export const ThingsContext = createContext<IThingsContextValue>(
  {} as IThingsContextValue
)

export const ThingsContextProvider: FunctionComponent = ({ children }) => {
  const [highlightedThingId, setHighlightedThingId] = useState<string>()

  const state = {
    highlightedThingId,
    setHighlightedThingId,
  }

  return (
    <ThingsContext.Provider value={state}>{children}</ThingsContext.Provider>
  )
}

interface IThingsContextValue {
  highlightedThingId?: string
  setHighlightedThingId: (thingId?: string) => void
}
