import {
  Autocomplete,
  TextField,
  TextFieldProps,
} from '@mapped/rivet/dist/mui/material'
import { find } from 'lodash'
import { useEffect, useState, forwardRef } from 'react'
import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import { IMapboxAddress, Mapbox } from '.'

export const MapboxSearch = forwardRef<HTMLInputElement, ISearch>(
  (
    {
      currentAddress,
      onSelectAddress,
      label,
      required,
      placeholder,
      margin,
      error,
      helperText,
    },
    ref
  ) => {
    const [isReady, setIsReady] = useState(false)
    const [defaultValue, setDefaultValue] = useState<string>()
    const [inputValue, setInputValue] = useState('')
    const [searchTerm] = useDebounce(inputValue, 500)

    const { data: addresses, isLoading } = useQuery(
      ['search', searchTerm],
      () => Mapbox.Geocode(searchTerm),
      {
        enabled: isReady,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    )

    useEffect(() => {
      if (!currentAddress) {
        return setIsReady(true)
      }

      const { place_name: initialPlaceName } = currentAddress || {}
      const { postalCode: initialPostalCode } = currentAddress?.details || {}

      Mapbox.Geocode(
        `${initialPlaceName || ''} ${initialPostalCode || ''}`
      ).then((options) => {
        const bestMatch = options?.[0]?.place_name
        setDefaultValue(bestMatch)
        setInputValue(bestMatch)
        setIsReady(true)
      })
    }, [])

    if (!isReady) {
      return <div style={{ height: 67 }} />
    }

    return (
      <Autocomplete
        className="mapbox-search"
        options={addresses?.map(({ place_name }) => place_name) || []}
        defaultValue={defaultValue}
        loading={isLoading}
        filterOptions={(options) => options}
        noOptionsText="Type an address"
        forcePopupIcon={false}
        loadingText="Searching..."
        onInputChange={(_, value) => setInputValue(value)}
        onChange={(_, place_name) => {
          if (!place_name) {
            return
          }

          const address = find(addresses, { place_name }) as IMapboxAddress

          onSelectAddress(Mapbox.Details(address))
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              ref={ref}
              label={label}
              required={required}
              placeholder={placeholder}
              margin={margin}
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                style: { padding: 5, paddingLeft: 8.5 },
              }}
            />
          </>
        )}
      />
    )
  }
)

interface ISearch
  extends Pick<
    TextFieldProps,
    'placeholder' | 'label' | 'required' | 'margin' | 'helperText' | 'error'
  > {
  currentAddress?: IMapboxAddress
  onSelectAddress: (address: IMapboxAddress) => void
}
