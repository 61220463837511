import { datadogLogs } from '@datadog/browser-logs'
import { Org, Permission, Role, User } from '@mapped/schema-graph-react-apollo'
import { FunctionComponent, createContext, useEffect, useState } from 'react'
import * as auth0 from '../auth/auth0'
import { analytics } from '../lib/segment'
import { Services } from '../services'

export const UserContext = createContext<IUserContextValue>(
  {} as IUserContextValue
)

export const UserContextProvider: FunctionComponent = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState<ILoggedUser | undefined | null>()
  const [orgless, setOrgless] = useState(false)
  const { user, organization, permissions, authToken } = loggedUser || {}
  const decodedTokenData = auth0.decodeToken(authToken)

  ;(window as any).loggedUser = loggedUser

  const isOBO = decodedTokenData['https://mapped.com/obo']

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    if (user) {
      analytics.identify(user.id!, {
        email: user.email,
      })

      datadogLogs.setLoggerGlobalContext({
        user: {
          id: user?.id,
          name: user?.name,
          org: {
            id: organization?.id,
            name: organization?.name,
          },
        },
      })
    }
  }, [user])

  async function fetch() {
    if (!auth0.getSessionAuthToken() && !auth0.getSessionRefreshToken()) {
      return setLoggedUser(null)
    }

    await auth0.renewAuthToken()
    await auth0.renewSessionAuthToken()

    const body = await auth0.fetchLoggedUser()

    let roles = body?.user?.roles || []

    if (!!body?.user && isOBO) {
      roles = [Role.Admin, Role.Explorer]
    }

    if (!loggedUser) {
      if (body?.error) {
        auth0.logout()
        setOrgless(body?.orgless)
        return setLoggedUser(null)
      }

      return setLoggedUser({ ...body, user: { ...body.user, roles } })
    }

    if (!body.error) {
      setLoggedUser({ ...body, user: { ...body.user, roles } })
    }
  }

  const isSandbox = organization?.id === Services.sandbox.org_id && !isOBO
  const isAdmin = !!user?.roles?.includes(Role.Admin)

  const value: IUserContextValue = {
    orgless,
    isOBO,
    isSandbox,
    authToken: auth0.getSessionAuthToken()!,
    user,
    organization,
    permissions: permissions || [],
    refetchLoggedUser: fetch,
    isAdmin,
  }

  if (loggedUser === undefined) {
    return null
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

interface IUserContextValue {
  isOBO: boolean
  authToken: string | undefined
  user: User | undefined
  organization: Org | undefined
  permissions: Permission[]
  isAdmin: boolean
  isSandbox?: boolean
  orgless?: boolean
  refetchLoggedUser: () => Promise<void>
}

export interface ILoggedUser {
  authToken: string
  user: User
  organization: Org
  permissions: Permission[]
}
