import { classnames } from '@mapped/rivet/dist/mapped/utils/classnames'
import { Edit } from '@mapped/rivet/dist/mui/icons'
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mapped/rivet/dist/mui/material'
import { styled, useTheme } from '@mapped/rivet/dist/mui/styles'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  CreateSourceData,
  loadStripe,
  SourceCreateParams,
  SourceResult,
  TokenResult,
} from '@stripe/stripe-js'
import { CSSProperties, FunctionComponent, useState } from 'react'
import { useNotifications } from '../../hooks/useNotifications'
import { Services } from '../../services'
import { IMapboxAddress, Mapbox } from '../mapbox'
import { CreditCardBrand } from './creditCardBrand'

const BillingInfoFormComponent: FunctionComponent<IBillingInfoForm> = (
  props
) => {
  const initialValues = new InitialValues(props.billingInfo)

  const notifications = useNotifications()
  const elements = useElements()
  const stripe = useStripe()

  const [firstName, setFirstName] = useState(initialValues.firstName())
  const [lastName, setLastName] = useState(initialValues.lastName())
  const [billingEmail, setBillingEmail] = useState(initialValues.billingEmail())
  const [phone, setPhone] = useState(initialValues.phone())
  const [billingAddress, setBillingAddress] = useState<
    IMapboxAddress['details']
  >(initialValues.billingAddress()?.details)

  const [isUpdatingCard, setIsUpdatingCard] = useState(!initialValues?.card())
  const [cardName, setCardName] = useState('')
  const [isCreatingCardToken, setIsCreatingCardToken] = useState(false)

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (isUpdatingCard && focusIncompleteStripeElement(elements)) {
      return
    }

    const values: IBillingInfoFormValues = {
      owner: {
        name: `${firstName} ${lastName}`,
        email: billingEmail,
        phone: phone ? phone : undefined,
        address: {
          line1: billingAddress?.streetAddress,
          postalCode: billingAddress?.postalCode,
          city: billingAddress?.city,
          state: billingAddress?.state?.name,
          country: billingAddress?.country?.code,
        } as any,
      },
    }

    if (isUpdatingCard) {
      setIsCreatingCardToken(true)

      const tokenResponse = await stripe?.createToken(
        elements?.getElement('cardNumber')!,
        {
          name: cardName,
          address_country: billingAddress?.country?.code,
          address_state: billingAddress?.state?.name,
          address_city: billingAddress?.city,
          address_zip: billingAddress?.postalCode,
          address_line1: billingAddress?.streetAddress,
        }
      )

      setIsCreatingCardToken(false)

      if (tokenResponse?.error) {
        notifications.push({
          type: 'error',
          message: tokenResponse.error.message,
        })
        return
      }

      values.token = tokenResponse?.token
    }

    props.onSubmit(values)
  }

  return (
    <Form onSubmit={onSubmit} style={props.style}>
      <h2 className="subtitle">Customer information</h2>

      <Row>
        <TextField
          label="First name"
          required={true}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Last name"
          required={true}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Row>

      <Row>
        <TextField
          label="Billing email"
          required={true}
          value={billingEmail}
          onChange={(e) => setBillingEmail(e.target.value)}
        />

        <TextField
          inputMode="tel"
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value?.replace(/\D/g, ''))}
        />
      </Row>

      <Mapbox.Search
        currentAddress={initialValues.billingAddress() as any}
        onSelectAddress={(addr) => setBillingAddress(addr?.details)}
        label="Billing Address"
        required={true}
        placeholder=""
      />

      <h2 className="subtitle" style={{ marginTop: 50 }}>
        Credit card
      </h2>

      {!!isUpdatingCard && (
        <>
          <TextField
            label="Name on card"
            fullWidth={true}
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            style={{ marginBottom: 20 }}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            required={true}
          />

          <StripeField label="Card number *" elementType="cardNumber" />

          <Row>
            <StripeField label="Card expiry *" elementType="cardExpiry" />
            <StripeField label="Card CVC *" elementType="cardCvc" />
          </Row>
        </>
      )}

      {!isUpdatingCard && (
        <CurrentCard>
          <div className="logo">
            <CreditCardBrand brand={initialValues.card()?.brand as any} />
          </div>

          <div className="details">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong>{initialValues.card()?.name}</strong>

              <Tooltip title="Edit credit card" placement="right">
                <IconButton
                  disableRipple={true}
                  size="small"
                  style={{ marginLeft: '10px' }}
                  onClick={() => setIsUpdatingCard(true)}
                >
                  <Edit
                    style={{ height: '18px', width: '18px' }}
                    color="primary"
                  />
                </IconButton>
              </Tooltip>
            </div>

            <p>•••• •••• •••• {initialValues.card()?.last4}</p>
            <p>
              {initialValues.card()?.exp_month} /{' '}
              {(initialValues.card()?.exp_year || 2022) - 2000}
            </p>
          </div>
        </CurrentCard>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
        }}
      >
        <Button
          variant="outlined"
          style={{ marginRight: 30 }}
          onClick={props.onCancel}
          disabled={props.isLoading || isCreatingCardToken}
        >
          Cancel
        </Button>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {(props.isLoading || isCreatingCardToken) && (
            <Typography sx={{ marginRight: '15px', opacity: '0.4' }}>
              (it may take a while)
            </Typography>
          )}
          <Button
            type="submit"
            disabled={props.isLoading || isCreatingCardToken}
          >
            {props.isLoading || isCreatingCardToken ? (
              <CircularProgress
                size={18}
                style={{ color: 'inherit' }}
                disableShrink={true}
              />
            ) : (
              props.submitButtonText || 'Submit'
            )}
          </Button>
        </div>
      </div>
    </Form>
  )
}

var StripePromise: ReturnType<typeof loadStripe> | undefined

export const BillingInfoForm: FunctionComponent<IBillingInfoForm> = (props) => {
  if (!StripePromise) {
    StripePromise = loadStripe(Services.stripe.publishable_key!)
  }

  return (
    <Elements stripe={StripePromise} options={{ locale: 'en' }}>
      <BillingInfoFormComponent {...props} />
    </Elements>
  )
}

const StripeField: FunctionComponent<{
  label: string
  elementType: TElementType
}> = ({ label, elementType }) => {
  const theme = useTheme()
  const [isFocused, setIsFocused] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const ElementComponent = StripeElementsMap[elementType].component
  const ElementOptions = (StripeElementsMap as any)[elementType].options

  return (
    <StripeFieldContainer
      className={classnames({
        focus: isFocused,
        invalid: isInvalid,
      })}
    >
      <label className="label">{label}</label>

      <ElementComponent
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => {
          StripeElementsMap[e.elementType].complete = e.complete
          setIsInvalid(!!e.error)
        }}
        options={{
          ...ElementOptions,

          style: {
            base: {
              fontSize: '17px',
              fontWeight: '300',
              color: theme.palette.text.primary,
            },
          },
        }}
      />
    </StripeFieldContainer>
  )
}

function focusIncompleteStripeElement(elements: any) {
  for (const elementType in StripeElementsMap) {
    if (!StripeElementsMap[elementType as TElementType].complete) {
      elements?.getElement(elementType)?.focus()
      return true
    }
  }

  return false
}

class InitialValues {
  private billingInfo: SourceResult['source']

  constructor(billingInfo: SourceResult['source']) {
    this.billingInfo = billingInfo
  }

  sourceId() {
    return this.billingInfo?.id
  }

  firstName() {
    return this.billingInfo?.owner?.name?.split(' ').shift()?.trim() || ''
  }

  lastName() {
    return (
      this.billingInfo?.owner?.name?.replace(this.firstName(), '')?.trim() || ''
    )
  }

  billingEmail() {
    return this.billingInfo?.owner?.email || ''
  }

  phone() {
    return this.billingInfo?.owner?.phone || ''
  }

  billingAddress() {
    const address = this.billingInfo?.owner?.address

    if (!address) {
      return undefined
    }

    const details = {
      country: { code: address?.country },
      state: { name: address?.state },
      city: address?.city,
      address: address?.line1,
      streetAddress: address?.line1,
      postalCode: (address as any)?.postalCode,
    }

    return {
      place_name: address.line1,
      details: details as IMapboxAddress['details'],
    }
  }

  card() {
    return this.billingInfo?.card
  }
}

const StripeElementsMap = {
  cardNumber: {
    component: CardNumberElement,
    complete: false,
    options: {
      showIcon: true,
    },
  },
  cardExpiry: {
    component: CardExpiryElement,
    complete: false,
  },
  cardCvc: {
    component: CardCvcElement,
    complete: false,
  },
}

const Form = styled.form`
  /* padding-right: 50px; */
  padding-bottom: 60px;

  .label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${(props) => props.theme.palette.text.primary};
  }

  .subtitle {
    font-size: 24px;
    font-weight: 500;
    margin-top: 0;
    padding-top: 30px;
    margin-bottom: 40px;
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  > div {
    width: 48%;
  }
`

const StripeFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  color: ${(props) => props.theme.palette.text.a50};

  .StripeElement {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid;
    padding: 0 16px;
    cursor: text;
    border-radius: 2px;

    > div {
      width: 100%;
    }

    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }

  &.focus {
    label,
    .StripeElement {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  &.invalid {
    label,
    .StripeElement {
      color: ${(props) => props.theme.palette.error.main} !important;
    }
  }
`

const CurrentCard = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 80px;

  .logo img {
    width: 110px;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;

    strong {
      text-transform: uppercase;
    }

    p {
      margin: 0;
      margin-top: 3px;
      font-size: 13px;
    }
  }
`

type TElementType = keyof typeof StripeElementsMap

export interface IBillingInfoFormValues {
  owner?: CreateSourceData['owner'] & {
    address: SourceCreateParams.Owner.Address & { postalCode?: string }
  }
  token?: TokenResult['token']
}

interface IBillingInfoForm {
  isLoading?: boolean
  billingInfo?: SourceResult['source']
  submitButtonText?: string
  onSubmit: (values: IBillingInfoFormValues) => void
  onCancel: () => void
  style?: CSSProperties
}
