import { ApolloProvider } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { getSessionAuthToken } from '../auth/auth0'
import { CoreApiClient } from '../lib/core-api'

export const ApolloContextProvider: FunctionComponent = ({ children }) => {
  const [client, setClient] = useState(CoreApiClient(getSessionAuthToken()!))

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data === 'token-refresh') {
        setClient(CoreApiClient(getSessionAuthToken()!))
      }
    })
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
