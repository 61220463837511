import Cookies, { CookieSetOptions } from 'universal-cookie'
import { ECookieName } from '../types/general'

export function setCookie(
  name: ECookieName,
  value: string,
  options?: CookieSetOptions
) {
  return new Cookies().set(name, value, options)
}

export function getCookie(name: ECookieName) {
  return new Cookies().get(name)
}

export function removeCookie(name: ECookieName, options?: any) {
  // cookie package decided to add cookie name validation to fix CVE
  // https://github.com/jshttp/cookie/pull/167
  // We used ":" inside of cookie name and it suddenly became a forbidden character.
  // We migrated from ":" to "_" but we still have old cookies with ":" in user's browser.
  // We need to remove it by using native document.cookie.
  // We can remove this workaround after 2033-01-01 when all old cookies expire.
  if (name.includes(':')) {
    let baseCookieRemoval = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${options?.path ?? '/'};`
    document.cookie = baseCookieRemoval

    // Attempt to delete the cookie on all subdomains
    const domainParts = window.location.hostname.split('.');
    while (domainParts.length > 0) {
      const domain = domainParts.join('.');
      document.cookie = `${baseCookieRemoval}domain=.${domain};`;
      domainParts.shift();
    }
  } else {
    new Cookies().remove(name, options)
  }
}

export function getAllCookies() {
  return new Cookies().getAll()
}
