import { gql } from '@apollo/client'
import { Permission } from '@mapped/schema-graph-react-apollo'
import { WebAuth } from 'auth0-js'
import axios from 'axios'
import jwt from 'jwt-simple'
import { NextApiRequest } from 'next'
import { EAuthProvider } from '../components/auth/providerButton'
import { CoreApiClient } from '../lib/core-api'
import { Services } from '../services'
import { ECookieName, EStorageKey, INextAPIError } from '../types/general'
import {
  getAllCookies,
  removeCookie
} from '../utils/cookies'
import { getEnumKeys, getEnumValues } from '../utils/enum'
import { logger } from '../utils/logger'

function getWebAuth(): WebAuth {
  const client = require('auth0-js')
  const { auth0 } = Services

  return new client.WebAuth({
    domain: auth0.domain,
    clientID: auth0.client_id,
    redirectUri: getRedirectUri(auth0.redirect_uri),
    audience: Services.auth0.audience,
    scope: auth0.scope,
    responseType: 'code',
  })
}

export async function renewAuthToken(force?: boolean) {
  if (!force && !isAuthTokenNearToExpire(getAuthToken()!)) {
    return true
  }

  if (!getRefreshToken()) {
    return false
  }

  const { data } = await axios.post<IOAuthTokenResponse & INextAPIError>(
    `/api/refreshToken`,
    {
      refreshToken: getRefreshToken(),
    }
  )

  if (data?.error) {
    logger.info('[refreshToken fail]', data?.stack)
    return false
  }

  setAuthToken(data?.access_token!)
  setRefreshToken(data?.refresh_token!)

  window.postMessage("token-refresh")

  return true
}

export async function renewSessionAuthToken(force?: boolean) {
  if (!force && !isAuthTokenNearToExpire(getSessionAuthToken()!)) {
    return true
  }

  if (!getSessionRefreshToken()) {
    return false
  }

  const { data } = await axios.post<IOAuthTokenResponse & INextAPIError>(
    `/api/refreshToken`,
    {
      refreshToken: getSessionRefreshToken(),
    }
  )

  if (data?.error) {
    logger.info('[refreshToken fail]', data?.stack)
    return false
  }

  setSessionAuthToken(data?.access_token!)
  setSessionRefreshToken(data?.refresh_token!)

  window.postMessage("token-refresh")

  return true
}

export async function tradeCodeForTokens(
  code: string
): Promise<IOAuthTokenResponse> {
  try {
    const { data } = await axios.post<IOAuthTokenResponse & INextAPIError>(
      `/api/issueTokens`,
      {
        code,
      }
    )

    if (data?.error) {
      logger.info('[issueTokens fail]', data?.stack)
    }

    return data
  } catch (e: any) {
    logger.info('[issueTokens fail]', e)
    return {}
  }
}

export function isAuthTokenNearToExpire(t: string) {
  const token = decodeToken(t)

  if (!token || token?.error) {
    return true
  }

  const currentTime = Date.now() / 1000 // in seconds
  const margin = 600 // 10 min in seconds

  if ((token.exp - margin) <= currentTime) {
    return true
  }

  return false
}

export function authorizeWithCredentials({
  email,
  password,
  signup,
  state,
  fullName,
  turnstileToken,
}: {
  email: string
  password: string
  signup?: boolean
  state?: string
  fullName?: string
  turnstileToken?: string
}): Promise<{ error?: string }> {
  const login = (): Promise<any> =>
    new Promise((resolve) => {
      getWebAuth().login(
        {
          email,
          password,
          realm: 'global',
          state,
          scope: Services.auth0.scope,
        },
        (err) => {
          logger.info(`[auth0 credentials login fail]`, err as any)
          resolve({ error: err?.description })
        }
      )
    })

  if (signup) {
    return new Promise((resolve) => {
      if (!turnstileToken) {
        return resolve({
          error:
            'Hey, are you a human? You must complete the captcha to continue.',
        })
      }

      getWebAuth().signup(
        {
          email,
          password,
          name: fullName,
          connection: 'global',
          //userMetadata: { turnstileToken },
        } as any,
        (err) => {
          if (err) {
            if (err.code === 'invalid_signup') {
              return resolve({
                error: "There's already an account registered with this email.",
              })
            }

            if (err?.description === 'invalid_turnstile_token') {
              return resolve({
                error:
                  'Hey, are you a human? You must complete the captcha to continue.',
              })
            }

            logger.info('[auth0 credentials signup fail]', err)
            return resolve({ error: err?.description })
          }

          resolve(login())
        }
      )
    })
  }

  return login()
}

export function getProviderByConnection(connection: string) {
  switch (connection) {
    case 'google-oauth2':
      return EAuthProvider.GOOGLE
    case 'windowslive':
      return EAuthProvider.MICROSOFT
    case 'github':
      return EAuthProvider.GITHUB
    default:
      return EAuthProvider.EMAIL
  }
}

export function authorizeWithSocial(
  provider: EAuthProvider,
  { state, connection }: { state?: string; connection?: string }
) {
  switch (provider) {
    case EAuthProvider.GOOGLE:
      return getWebAuth().authorize({ connection: 'google-oauth2', state })
    case EAuthProvider.MICROSOFT:
      return getWebAuth().authorize({ connection: 'windowslive', state })
    case EAuthProvider.GITHUB:
      return getWebAuth().authorize({ connection: 'github', state })
    case EAuthProvider.SSO:
      return getWebAuth().authorize({ connection, state })
  }
}

export function sendPasswordRecoveryEmail(email: string): Promise<boolean> {
  return new Promise((resolve) => {
    getWebAuth().changePassword({ email, connection: 'global' }, (err) => {
      logger.info(`[auth0 password recovery fail]`, err as any)
      resolve(err ? false : true)
    })
  })
}

export function decodeToken(token?: string) {
  try {
    const t = jwt.decode(token ?? '', '', true) ?? {}

    return {
      authToken: token,
      orgId: t['https://mapped.com/org'],
      email: t['https://mapped.com/email'],
      ...t,
    }
  } catch (error) {
    return { error }
  }
}

export async function fetchLoggedUser(req?: NextApiRequest) {
  let authToken = req ? getAuthToken(req) : getSessionAuthToken()

  try {
    const { permissions } = decodeToken(authToken!)

    const normalizedPermissions = permissions?.map((perm: string) =>
      perm.toUpperCase().replace(/\./g, '_')
    )

    const customerPermissions = getEnumValues(Permission)
    const filteredPermissions = normalizedPermissions?.filter((perm: string) =>
      customerPermissions.includes(perm)
    )

    const query = await CoreApiClient(authToken!).query({
      query: LOGGED_USER_QUERY,
    })

    return { permissions: filteredPermissions, authToken, ...query.data }
  } catch (error) {
    const { authToken: token, orgId } = decodeToken(authToken!)
    return { error, orgless: !!token && !orgId }
  }
}

export function clearNonMappedCookies() {
  Object.keys(getAllCookies()).forEach((cookie) => {
    if (!getEnumKeys(ECookieName).includes(cookie)) {
      removeCookie(cookie as any)
    }
  })
}

clearNonMappedCookies()

export function logout() {
  clearNonMappedCookies()

  localStorage.removeItem(EStorageKey.AUTH_TOKEN)
  localStorage.removeItem(EStorageKey.REFRESH_TOKEN)
  sessionStorage.removeItem(EStorageKey.AUTH_TOKEN)
  sessionStorage.removeItem(EStorageKey.REFRESH_TOKEN)
}

export function getRefreshToken() {
  return localStorage.getItem(EStorageKey.REFRESH_TOKEN)
}

export function getSessionRefreshToken() {
  return sessionStorage.getItem(EStorageKey.REFRESH_TOKEN)
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(EStorageKey.REFRESH_TOKEN, refreshToken)
}

export function setSessionRefreshToken(refreshToken: string) {
  sessionStorage.setItem(EStorageKey.REFRESH_TOKEN, refreshToken)
}

export function setAuthToken(authToken: string) {
  localStorage.setItem(EStorageKey.AUTH_TOKEN, authToken)
}

export function setSessionAuthToken(authToken: string) {
  sessionStorage.setItem(EStorageKey.AUTH_TOKEN, authToken)
}

export function getAuthToken(req?: NextApiRequest) {
  if (typeof window !== "undefined") {
    return localStorage.getItem(EStorageKey.AUTH_TOKEN)
  }

  return req?.headers?.authorization
}

export function getSessionAuthToken() {
  let token = sessionStorage.getItem(EStorageKey.AUTH_TOKEN);
  return token || getSessionRefreshToken() ? token : getAuthToken();
}

const stc = {
  token: '',
  expiration: new Date(),
}

export async function getServiceToken() {
  try {
    const { MAPPED_TOKEN_EXCHANGE_URI, MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_ID, MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_SECRET } = process.env

    if (stc.expiration > new Date()) {
      return stc.token
    }

    const res = await axios.post(MAPPED_TOKEN_EXCHANGE_URI!,
      {
        client_id: MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_ID,
        client_secret: MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_SECRET,
        grant_type: 'client_credentials'
      },

      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: btoa(MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_ID + ':' + MAPPED_SERVICE_TOKEN_EXCHANGE_CLIENT_SECRET)
        }
      }
    )

    stc.token = res.data.access_token
    stc.expiration = new Date(Date.now() + res.data.expires_in * 1000)

    return stc.token
  } catch (e) {
    console.log(e)
    return ''
  }
}

function getRedirectUri(baseUrl: string): string {
  const params = new URLSearchParams(location.search)
  const returnTo = params.get('return_to') || ''

  return `${baseUrl}?return_to=${returnTo}`
}

const LOGGED_USER_QUERY = gql`
  query loggedUser {
    user: me {
      id
      name
      email
      nickname
      emailVerified
      roles
    }

    organization {
      id
      name
      stripeCustomerId
      created
    }
  }
`

export interface IOAuthTokenResponse {
  error?: string
  access_token?: string
  refresh_token?: string
  expires_in?: number
  provider?: EAuthProvider
  orgId?: string
}
