import { merge } from 'lodash'
import shuffler from '../utils/shuffler'

export const Services = {
  app: {
    env: process.env.APPLICATION_ENVIRONMENT || 'Local',
    version: '',
    cookie_domain: process.env.COOKIE_DOMAIN || 'localhost',
  },

  turnstile: {
    site_key: process.env.TURNSTILE_SITE_KEY,
  },

  auth0: {
    client_id: process.env.AUTH0_CONSOLE_CLIENT_ID,
    client_secret: process.env.AUTH0_CONSOLE_CLIENT_SECRET,
    console_micro_client_id: process.env.AUTH0_CONSOLEMICRO_CLIENT_ID,
    console_micro_client_secret: process.env.AUTH0_CONSOLEMICRO_CLIENT_SECRET,
    audience: 'https://api.mapped.com/graphql',
    scope: 'openid profile offline_access',
    domain: process.env.AUTH0_DOMAIN,
    redirect_uri: process.env.AUTH0_REDIRECT_URI,
  },

  graphql_api: {
    uri: process.env.GRAPHQL_API_URI,
  },

  building_renderer: {
    uri: process.env.BUILDING_RENDERER_URI,
  },

  contentstack: {
    delivery_token: process.env.CONTENTSTACK_DELIVERY_TOKEN,
    api_key: process.env.CONTENTSTACK_API_KEY,
    env_name: process.env.CONTENTSTACK_ENV_NAME,
  },

  split: {
    api_key: process.env.SPLIT_API_KEY,
  },

  zendesk: {
    shared_secret: process.env.ZENDESK_SSO_SHARED_SECRET,
  },

  datadog: {
    env: process.env.DATADOG_ENV,
  },

  grpc_services: {
    orgs: process.env.ORGS_GRPC_SERVICE_ENDPOINT,
    billing: process.env.BILLING_GRPC_SERVICE_ENDPOINT,
    business: process.env.BUSINESS_GRPC_SERVICE_ENDPOINT,
  },

  mapbox: {
    token: process.env.MAPBOX_TOKEN,
  },

  sandbox: {
    org_id:
      process.env.APPLICATION_ENVIRONMENT === 'Prod'
        ? 'ORGUmvDdxfFU7UBvJ8Msx2A6J'
        : 'ORGFEdBM93rarcXPD3hjMHxnD',

    token: process.env.SANDBOX_TOKEN,
  },

  connectors: {
    client_uri: process.env.CONNECTORS_CLIENT_URI,
  },

  stripe: {
    publishable_key: process.env.STRIPE_PUBLISHABLE_KEY,
  },

  devportal: {
    url: process.env.DEVPORTAL_URL,
  },

  anaxi: {
    url: process.env.ANAXI_URL,
  },

  grid: {
    pointsChunkSize: process.env.POINTS_CHUNK_SIZE || 100000,
    pointsConcurrencyLimit: process.env.POINTS_CONCURRENCY_LIMIT || 1,
    pointsPageSize: process.env.POINTS_PAGE_SIZE || 100,
    pointsPaginationLimit: process.env.POINTS_PAGINATION_LIMIT || 10000,
  },
}

export async function loadClientServices(): Promise<boolean> {
  if (!process.browser) {
    return true
  }

  const res = await fetch('/api/client-services')
  const clientServices = JSON.parse(shuffler(await res.text(), true))

  merge(Services, clientServices)

  return true
}
