import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'

import { Services } from '../../services'

const defaultCache = new InMemoryCache().restore({})

export function CoreApiClient(token?: string, tokenType: string = 'Bearer') {
  const httpLink = new HttpLink({
    uri: Services.graphql_api.uri,
    credentials: 'omit',
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (!token) {
      // Cancel the request if token is not provided
      return forward(operation)
    }

    operation.setContext({
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    })

    return forward(operation)
  })

  return new ApolloClient({
    cache: process.browser ? defaultCache : new InMemoryCache().restore({}),
    link: concat(authMiddleware, httpLink),
  })
}
