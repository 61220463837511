import { Role } from '@mapped/schema-graph-react-apollo'
import Script from 'next/script'
import { FunctionComponent, useContext } from 'react'
import { BillingContext, EPlanSlug } from '../../contexts/billing'
import { UserContext } from '../../contexts/user'
import { Services } from '../../services'
import { ECookieName } from '../../types/general'
import { getCookie, setCookie } from '../../utils/cookies'
import { isCypress } from '../../utils/cypress'

export const ThirdyPartyScripts: FunctionComponent = () => {
  const { user, organization, isAdmin } = useContext(UserContext)
  const { subscriptionPlan } = useContext(BillingContext)
  const nonce = process.env.NEXT_PUBLIC_NONCE as string

  if (window.location.host.startsWith('app-dev')) {
    setCookie(ECookieName.INTERNAL, 'true', {
      path: '/',
      expires: new Date('2033-01-01'),
    })
  }

  const isLogged = !!user
  const isInternal = !!getCookie(ECookieName.INTERNAL)
  const isLocal = Services.app.env === 'Local'

  if (isLocal || isCypress() || isInternal) {
    return null
  }

  return (
    <>
      <Script
        nonce={nonce}
        type="text/javascript"
        id="hs-script-loader"
        async={true}
        defer={true}
        src="//js.hs-scripts.com/22297332.js"
      />

      <Script
        nonce={nonce}
        async={true}
        src="https://www.googletagmanager.com/gtag/js?id=G-J1C43NRHH0"
        onLoad={() => {
          const windw = window as any

          windw.dataLayer = windw.dataLayer || []

          function gtag() {
            windw.dataLayer.push(arguments)
          }

          windw.gtag = gtag
          windw.gtag('js', new Date())
          windw.gtag('config', 'G-J1C43NRHH0')
          windw.gtag('config', 'AW-10978453293') // ads conversion
          windw.dataLayer?.push({
            user_id: user?.id,
            org_id: organization?.id,
          })
        }}
      />

      <Script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
        (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

            pendo.initialize({
              visitor: {
                id: '${user?.id}',
                email: '${user?.email}',
                full_name: '${user?.name}',
                role: '${isAdmin ? Role.Admin : Role.Explorer}',
              },

              account: {
                creationDate: '${organization?.created}',
                id: '${organization?.id}',
                name: '${organization?.name}',
                is_paying: ${subscriptionPlan !== EPlanSlug.STARTER},
                planLavel: '${subscriptionPlan
                  ?.replace('plan_', '')
                  ?.replace('.platform', '')}',
                // planPrice: ''
              }
            });
        })('dd7d7562-4297-4510-41b7-af1ae892e329');
      `,
        }}
      ></Script>

      {isLogged && (
        <Script
          nonce={nonce}
          async={true}
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=ee63588c-aff9-4749-bc94-21b7a7765fca"
        />
      )}
    </>
  )
}
