import { Alert, Snackbar } from '@mapped/rivet/dist/mui/material'
import { useTheme } from '@mapped/rivet/dist/mui/styles'
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useRef,
  useState,
} from 'react'
import ReactConfetti from 'react-confetti'
import { proxy, useSnapshot } from 'valtio'

export const NotificationsContext = createContext<INotificationsContextValue>(
  {} as INotificationsContextValue
)

export const NotificationsContextProvider: FunctionComponent = ({
  children,
}) => {
  const theme = useTheme()

  const [showConfetti, setShowConfetti] = useState(false)
  const { current: state } = useRef(
    proxy<{ notifications: INotification[] }>({ notifications: [] })
  )

  useSnapshot(state)

  function remove(id: string) {
    state.notifications = state.notifications.filter((n) => n.id !== id)
  }

  function confetti() {
    if (showConfetti) {
      return
    }

    setShowConfetti(true)
    setTimeout(() => setShowConfetti(false), 11000)
  }

  function push(notification: INotification) {
    const id = notification.id || Math.random().toString(16).substring(2)
    const duration = notification.duration || DEFAULT_DURATION

    state.notifications = state.notifications
      .splice(-2)
      .concat({ ...notification, id, duration })
  }

  return (
    <NotificationsContext.Provider
      value={{ notifications: state.notifications, push, remove, confetti }}
    >
      {children}

      {showConfetti && (
        <ReactConfetti
          recycle={false}
          numberOfPieces={1000}
          tweenDuration={10000}
          style={{ zIndex: 99999 }}
        />
      )}

      {state.notifications.map(({ id, type, message, duration }, idx) => {
        const offsets = [74, 134, 194]

        const colors = {
          error: theme.palette.error.main,
          success: theme.palette.success.main,
          warning: theme.palette.warning.main,
          info: theme.palette.info.main,
        }

        return (
          <Snackbar
            key={id}
            open={true}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={(_, reason) => reason !== 'clickaway' && remove(id!)}
            sx={{
              top: `${offsets[idx]}px !important`,
              transition: `top 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
            }}
          >
            <Alert
              severity={type}
              onClose={() => remove(id!)}
              elevation={2}
              sx={{
                backgroundColor: `${colors[type]}`,
                border: 'none',
                borderRadius: '3px',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.16), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                '& *': {
                  fontWeight: 'bold',
                  color: 'white !important',
                },
              }}
            >
              {message}
            </Alert>
          </Snackbar>
        )
      })}
    </NotificationsContext.Provider>
  )
}

const DEFAULT_DURATION = 6000

interface INotificationsContextValue {
  notifications: INotification[]
  push: (notification: INotification) => void
  remove: (id: string) => void
  confetti: (fn?: any) => void
}

interface INotification {
  type: 'error' | 'success' | 'warning' | 'info'
  message: ReactNode | string
  id?: string
  duration?: number
}
