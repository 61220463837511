import {
  Feature,
  Layer,
  Marker,
} from '@mapped/rivet/node_modules/react-mapbox-gl'
import { Services } from '../../services'
import { MapboxSearch } from './search'
import { MapboxView } from './view'

//@ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'

if (process.browser) {
  mapboxgl.workerClass = new Worker(
    new URL('mapbox-gl/dist/mapbox-gl-csp-worker', import.meta.url)
  )
}

function Geocode(searchTerm: string): Promise<IMapboxAddress[]> {
  if (!searchTerm || searchTerm.length < 3) {
    return new Promise((resolve) => resolve([]))
  }

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json?access_token=${Services.mapbox.token}&types=address`

  return fetch(url)
    .then((e) => e.json())
    .then((e) => e?.features ?? [])
}

function Details(address: IMapboxAddress) {
  const details: IMapboxAddress['details'] = {}

  address.context.forEach((ctx) => {
    if (ctx.id?.startsWith('place')) {
      details.city = ctx.text
    }

    if (ctx.id?.startsWith('region')) {
      details.state = {
        name: ctx.text,
        code: ctx.short_code?.split('-').pop()?.toUpperCase(),
      }
    }

    if (ctx.id?.startsWith('country')) {
      details.country = {
        name: ctx.text,
        code: ctx.short_code?.toUpperCase(),
      }
    }

    if (ctx.id?.startsWith('postcode')) {
      details.postalCode = ctx.text
    }
  })

  details.streetAddress = `${address.address || ''} ${address.text || ''}`

  return { ...address, details }
}

export const Mapbox = {
  Search: MapboxSearch,
  View: MapboxView,
  Geocode,
  Layer,
  Feature,
  Marker,
  Details,
}

export interface IMapboxAddress {
  id: string
  place_name: string
  details?: {
    streetAddress?: string
    postalCode?: string
    city?: string
    state?: { name?: string; code?: string }
    country?: { name?: string; code?: string }
  }
  context: {
    id: string
    text: string
    short_code?: string
  }[]
  geometry: {
    coordinates: [number, number]
  }
  // Dumb names for mapbox internal properties
  address: string // house number
  text: string // street name
}
