import { DetailedHTMLProps, FC, ImgHTMLAttributes } from 'react'

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  brand:
    | 'amex'
    | 'diners'
    | 'discover'
    | 'jcb'
    | 'mastercard'
    | 'unionpay'
    | 'visa'
}

export const CreditCardBrand: FC<Props> = ({ brand, ...props }) => {
  const normalizedBrand = brand
    ?.toLowerCase()
    ?.replace('american express', 'amex')

  return (
    <img
      alt={brand}
      src={`/img/card-brands/${normalizedBrand}.svg`}
      {...props}
    />
  )
}
