import { getMappedTheme } from '@mapped/rivet/dist/mapped/theme'
import { CssBaseline, LinearProgress } from '@mapped/rivet/dist/mui/material'
import { ThemeProvider } from '@mapped/rivet/dist/mui/styles'
import 'mapbox-gl/dist/mapbox-gl.css'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-virtualized-tree/lib/main.css'
import 'react-virtualized/styles.css'
import smoothscroll from 'smoothscroll-polyfill'
import { DatadogInitializer } from '../components/datadog'
import { MobileDeviceWarning } from '../components/mobile-device-warning'
import { ThirdyPartyScripts } from '../components/thirdy-party-scripts'
import { ModalOnStage } from '../contexts/modals'
import { AppProviders } from '../contexts/providers'
import '../css/main.css'
import { analytics } from '../lib/segment'
import { createMappedUtils } from '../utils/mapped'

if (process.browser) {
  createMappedUtils()
  smoothscroll.polyfill()
}

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  const { asPath } = useRouter()
  const [isAppReady, setIsAppReady] = useState(false)

  useEffect(() => {
    if (isAppReady && location.hash?.includes('fromLogin')) {
      analytics.track('login')
    }
  }, [isAppReady])

  useEffect(() => {
    isAppReady && setTimeout(analytics.page, 1000)
  }, [isAppReady, asPath])

  return (
    <>
      <Head>
        <title>Mapped Console</title>
      </Head>

      <AppProviders>
        <CssBaseline />

        <Component {...pageProps} />

        <MobileDeviceWarning />

        <DatadogInitializer />

        <div
          ref={() => {
            if (!isAppReady) {
              setIsAppReady(true)
            }
          }}
        />

        <ModalOnStage />

        <ThirdyPartyScripts />
      </AppProviders>

      {(!isAppReady || asPath.includes('/callback')) && <PageLoader />}
    </>
  )
}

const PageLoader: FunctionComponent = () => {
  return (
    <div
      id="page-loader"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'white',
        zIndex: 10000,
      }}
    >
      <ThemeProvider theme={getMappedTheme()}>
        <LinearProgress variant="indeterminate" style={{ width: '200px' }} />
      </ThemeProvider>
    </div>
  )
}

export default App
