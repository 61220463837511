function shuffler(input: string, decode?: boolean) {
  let v = Math.random().toString().slice(2, 6)
  let str = input

  if (decode) {
    v = str.slice(-4)
    str = str.slice(0, str.length - 4)
  }

  const seed = [
    0x5b, 0x81, 0x37, 0x36, 0x38, 0x5, 0x9c, 0x3d, 0x9f, 0x74, 0x64, 0xa7, 0x6c,
    0x3f, 0x9f, 0xb0, 0x97, 0x38, 0x24, 0x42, 0x71, 0x3b, 0x1f, 0x4e, 0x90,
    0xa1, 0x87, 0xa3, 0x48, 0x62, 0xc2, 0x26, 0xb, 0x7b, 0xf, 0x95, 0xb6, 0x30,
    0x3a, 0x6c, 0x1b, 0x3e, 0x32, 0xba, 0xf, 0xa7, 0x56, 0x9e, 0xa6, 0x50, 0x5a,
    0x9d, 0x6, 0x9c, 0xab, 0xb8, 0xbd, 0x22, 0x58, 0x31, 0x9c, 0x26, 0x92, 0x77,
    0xaa, 0x90, 0x28, 0xb7, 0xc7, 0x6f, 0x45, 0xac, 0x1a, 0x29, 0x99, 0x75,
    0xbb, 0xaa, 0x5e, 0xa1, 0x3c, 0x8c, 0x9d, 0x2, 0xa, 0x44, 0x69, 0xa3, 0x59,
    0x29, 0xb1, 0x2b, 0x28, 0x5a, 0x48, 0x88, 0x87, 0xb6, 0x1d, 0x84,
  ].map((n) => Number(v.split('').reverse().join('')) + n)

  const chars = Array.from(str)

  const swap = (a: number, b: number) =>
    ([chars[a], chars[b]] = [chars[b], chars[a]])

  for (
    var i = decode ? str.length - 1 : 0;
    (decode && i >= 0) || (!decode && i < str.length);
    i += decode ? -1 : 1
  ) {
    swap(seed[i % seed.length] % str.length, i)
  }

  const result = chars.join('')
  return result + (decode ? '' : v)
}

export default shuffler
