import { gql } from '@apollo/client'
import { getSessionAuthToken } from '../auth/auth0'
import { CoreApiClient } from '../lib/core-api'
import { ECookieName } from '../types/general'
import { removeCookie, setCookie } from './cookies'

export function createMappedUtils() {
  ; (window as any)['mapped'] = {
    internal: (is: boolean) => {
      const cookieOptions = {
        path: '/',
      }

      if (is === false) {
        removeCookie(ECookieName.INTERNAL, cookieOptions)
        return window.location.reload()
      }

      setCookie(ECookieName.INTERNAL, 'true', {
        ...cookieOptions,
        sameSite: 'lax',
        expires: new Date('2033-01-01'),
      })

      window.location.reload()
    },

    user: () => (window as any).loggedUser,

    jwt: () => {
      const token = getSessionAuthToken()

      if (!token) {
        return 'No JWT Token to copy'
      }

      copyString(token as string)

      return 'JWT Token copied to clipboard'
    },

    query: (str: string, variables: any) => {
      const token = getSessionAuthToken()!

      return CoreApiClient(token).query({
        query: gql`
          ${str}
        `,
        variables,
      })
    },
  }
}

function copyString(str: string) {
  const input = document.createElement('input')
  input.id = 'strToCopy'
  input.value = str
  input.style.opacity = '0'
  document.body.append(input)
    ; (document.getElementById('strToCopy') as any)?.select()
  document.execCommand('copy')
  document.getElementById('strToCopy')?.remove()
}
