import Analytics from 'analytics'
// @ts-ignore
import segmentPlugin from '@analytics/segment'

export const analytics = Analytics({
  app: 'Console',
  plugins: [
    segmentPlugin({
      writeKey: 'rtyPdHfCTMJa87M50ek6EonGZw6fZ9B4',
    }),
  ],
})
