import { SourceResult } from '@stripe/stripe-js'
import axios from 'axios'
import { find } from 'lodash'
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QueryObserverBaseResult, useQuery } from 'react-query'
import { IBillingInfoFormValues } from '../components/billing/billingInfoForm'
import { SubscriptionModal } from '../components/billing/subscriptionModal'
import { useModals } from '../hooks/useModals'
import { ECookieName, EStorageKey } from '../types/general'
import { getCookie } from '../utils/cookies'
import { logger } from '../utils/logger'
import { UserContext } from './user'

export const BillingContext = createContext<IBillingContextValue>(
  {} as IBillingContextValue
)

export const BillingContextProvider: FunctionComponent<{
  initialCalculations?: ICalculation[]
}> = ({ children, initialCalculations }) => {
  const [calculations, setCalculations] = useState<ICalculation[]>(
    initialCalculations || []
  )

  const { authToken, isAdmin, isSandbox } = useContext(UserContext)

  const isFetchingRef = useRef(false)
  const [isFetchingCalculation, setIsFetchingCalculation] = useState(false)
  const [termLength, setTermLength] = useState(ETermLength.MONTH)
  const [plan, setPlan] = useState<EPlanSlug>()
  const [gateway, setGateway] = useState(EGatewaySlug.VIRTUAL)
  const [apiCreditsQuantity, setApiCreditsQuantity] = useState(million(5))
  const [gatewayQuantity, setGatewayQuantity] = useState(1)
  const [buildingQuantity, setBuildingQuantity] = useState(1)

  const subscriptionQuery = useQuery(
    'subscription',
    () =>
      axios.get<{ subscription?: ISubscription }>('/api/billing/subscription', {
        headers: { Authorization: authToken },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!authToken,
      onError: (e: any) =>
        logger.error('[subscription query error]', e?.response?.data?.stack),
    }
  )

  const subscription = subscriptionQuery?.data?.data?.subscription

  const billingInfoQuery = useQuery(
    'billingInfo',
    () =>
      axios.get<{ source: SourceResult['source'] }>(
        '/api/billing/billing-info',
        { headers: { Authorization: authToken } }
      ),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!authToken &&
        subscription?.planProvider === EPlanProvider.MAPPED &&
        subscription?.plan !== EPlanSlug.STARTER,
      onError: (e: any) =>
        logger.error('[billing info query error]', e?.response?.data?.stack),
    }
  )

  const billingInfo = billingInfoQuery?.data?.data?.source

  async function updateBillingInfo(values: IBillingInfoFormValues) {
    const { data } = await axios.post(
      '/api/billing/update-billing-info',
      values,
      { headers: { Authorization: authToken } }
    )

    if (!data?.error) {
      await refetchUntil(billingInfoQuery.refetch, ({ source }) => {
        const card = source?.card
        const newCard = values?.token?.card
        const email = source?.owner?.email
        const newEmail = values?.owner?.email
        const address = source?.owner?.address
        const newAddress = values?.owner?.address

        if (newCard && card?.last4 !== newCard?.last4) {
          return false
        }

        return (
          email === newEmail &&
          address?.line1 === newAddress?.line1 &&
          address?.postalCode === newAddress?.postalCode
        )
      })

      return true
    }

    logger.error('[update billing info error]', data?.stack)
    return !data?.error
  }

  async function updateSubscription() {
    const { data } = await axios.post(
      '/api/billing/update-subscription',
      {
        items: [
          {
            slug: plan,
            quantity: buildingQuantity,
          },
        ],
      },
      { headers: { Authorization: authToken } }
    )

    if (!data?.error) {
      await refetchUntil(
        subscriptionQuery.refetch,
        ({ subscription }) => subscription?.plan === plan
      )

      return true
    }

    logger.error('[update subscription error]', data?.stack)
    return !data?.error
  }

  async function fetchCalculations(
    items: { slug: string; quantity: number }[]
  ) {
    const itemsToFetch = items.filter(
      ({ slug, quantity }) => !find(calculations, { slug, quantity })
    )

    if (!!isFetchingRef.current || !itemsToFetch.length) {
      return
    }

    isFetchingRef.current = true
    setIsFetchingCalculation(true)

    const { data: fetchedCalcs } = await axios.post<ICalculation[]>(
      '/api/billing/calculations',
      itemsToFetch
    )

    setCalculations([...calculations, ...fetchedCalcs])

    isFetchingRef.current = false
    setIsFetchingCalculation(false)
  }

  function shouldShowUpgradeBanner() {
    return (
      !isSandbox &&
      isAdmin &&
      !subscriptionQuery?.isLoading &&
      getSubscriptionPlan() === EPlanSlug.STARTER &&
      !sessionStorage.getItem(EStorageKey.SHOULD_HIDE_UPGRADE_BANNER)
    )
  }

  function getSubscriptionPlanProvider(): EPlanProvider {
    return subscription?.planProvider || EPlanProvider.MAPPED
  }

  function getSubscriptionPlan(): EPlanSlug {
    return subscription?.plan || EPlanSlug.STARTER
  }

  function getSubscriptionBuildingQuantity(): number {
    return subscription?.buildingQuantity || 1
  }

  function getPlanPrice(slug: EPlanSlug) {
    const c = find(calculations, { slug, quantity: buildingQuantity })
    return normalizePrice(c?.unitPrice)
  }

  function getGatewayPrice() {
    const c = find(calculations, { slug: gateway, quantity: gatewayQuantity })
    return normalizePrice(c?.unitPrice)
  }

  function getApiCreditsPrice(slug?: EApiPackageSlug) {
    const c = find(calculations, {
      slug: slug || getBestApiPackageSlugForQuantity(apiCreditsQuantity),
      quantity: apiCreditsQuantity,
    })

    return {
      fixedPrice: normalizePrice(c?.fixedPrice),
      overagePrice: normalizePrice(c?.overagePrice),
    }
  }

  useEffect(() => {
    fetchCalculations([
      { slug: EPlanSlug.PRO, quantity: buildingQuantity },
      { slug: EPlanSlug.BASIC, quantity: buildingQuantity },
    ])
  }, [buildingQuantity, gatewayQuantity])

  // useEffect(() => {
  //   fetchCalculations([
  //     { slug: EApiPackageSlug.PAY_AS_YOU_GO, quantity: apiCreditsQuantity },
  //     {
  //       slug: getBestApiPackageSlugForQuantity(apiCreditsQuantity),
  //       quantity: apiCreditsQuantity,
  //     },
  //   ])
  // }, [apiCreditsQuantity])

  return (
    <BillingContext.Provider
      value={{
        isFetchingCalculation,
        isLoadingSubscription: subscriptionQuery?.isLoading,
        plan,
        setPlan,
        termLength,
        setTermLength,
        gateway,
        setGateway,
        apiCreditsQuantity,
        setApiCreditsQuantity,
        buildingQuantity,
        setBuildingQuantity,
        gatewayQuantity,
        setGatewayQuantity,
        getPlanPrice,
        getGatewayPrice,
        getApiCreditsPrice,
        updateSubscription,
        updateBillingInfo,
        subscription,
        billingInfo,
        refetchBillingInfo: billingInfoQuery.refetch,
        refetchSubscription: subscriptionQuery.refetch,
        subscriptionPlanProvider: getSubscriptionPlanProvider(),
        subscriptionPlan: getSubscriptionPlan(),
        subscriptionBuildingQuantity: getSubscriptionBuildingQuantity(),
        shouldShowUpgradeBanner: shouldShowUpgradeBanner(),
      }}
    >
      <WishingPlanTracker>{children}</WishingPlanTracker>
    </BillingContext.Provider>
  )
}

const WishingPlanTracker: FunctionComponent = ({ children }) => {
  const { authToken } = useContext(UserContext)
  const { setPlan } = useContext(BillingContext)
  const modals = useModals()

  useEffect(() => {
    if (!authToken) {
      return
    }

    const wishingPlan = getCookie(ECookieName.WISHING_PLAN)
    const isValidPlan = Object.values(EPlanSlug).includes(wishingPlan)

    if (isValidPlan) {
      setPlan(wishingPlan)
      modals.open(SubscriptionModal)
    }
  }, [authToken])

  return <>{children}</>
}

function getBestApiPackageSlugForQuantity(qty: number) {
  switch (true) {
    case qty >= million(50):
      return EApiPackageSlug.M50
    case qty >= million(20):
      return EApiPackageSlug.M20
    case qty >= million(10):
      return EApiPackageSlug.M10
    case qty >= million(5):
      return EApiPackageSlug.M5
    default:
      return EApiPackageSlug.PAY_AS_YOU_GO
  }
}

function normalizePrice(n: any) {
  return (n || 0) / 100
}

async function refetchUntil(refetchFn: any, checker: (data: any) => boolean) {
  const request = () => {
    return new Promise((resolve) => {
      refetchFn()?.then((res: any) => {
        if (checker(res?.data?.data || {})) {
          return resolve(true)
        }

        setTimeout(() => resolve(request()), 1500)
      })
    })
  }

  return await request()
}

export function getPriceString(price: number, hideIfZero?: boolean) {
  if (!price && hideIfZero) {
    return ''
  }

  if (!price) {
    return 'FREE'
  }

  if (price < 1) {
    return '$' + parseFloat(price.toFixed(9))
  }

  return '$' + Number(price).toLocaleString('en')
}

export const million = (q: number) => q * 1000000

export function numberToWord(n: number) {
  if (n >= 1000000) {
    return String(n / 1000000 + 'M')
  }

  if (n >= 1000) {
    return String(n / 1000 + 'M')
  }

  return n
}

interface IBillingContextValue {
  isFetchingCalculation: boolean
  isLoadingSubscription: boolean
  termLength: ETermLength
  plan?: EPlanSlug
  setPlan: (p?: EPlanSlug) => void
  gateway: EGatewaySlug
  setGateway: (g: EGatewaySlug) => void
  setTermLength: (t: ETermLength) => void
  apiCreditsQuantity: number
  setApiCreditsQuantity: (n: number) => void
  buildingQuantity: number
  setBuildingQuantity: (n: number) => void
  gatewayQuantity: number
  setGatewayQuantity: (n: number) => void
  getPlanPrice: (s: EPlanSlug) => number
  getGatewayPrice: () => number
  getApiCreditsPrice: (slug?: EApiPackageSlug) => {
    fixedPrice: number
    overagePrice: number
  }
  billingInfo: SourceResult['source']
  updateBillingInfo: (values: IBillingInfoFormValues) => Promise<boolean>
  updateSubscription: () => Promise<boolean>
  refetchBillingInfo: QueryObserverBaseResult['refetch']
  refetchSubscription: QueryObserverBaseResult['refetch']
  subscription: ISubscription | undefined
  subscriptionPlanProvider: EPlanProvider
  subscriptionPlan: EPlanSlug
  subscriptionBuildingQuantity: number
  shouldShowUpgradeBanner: boolean
}

export interface ISubscription {
  id: string
  plan: EPlanSlug
  planProvider: EPlanProvider
  buildingQuantity: number
}

export enum EPlanProvider {
  MAPPED = 'mapped',
  AZURE = 'azure',
}

export enum EPlanSlug {
  STARTER = 'plan_starter.platform',
  BASIC = 'plan_basic.platform',
  PRO = 'plan_pro.platform',
}

export enum EGatewaySlug {
  VIRTUAL = 'gateway.gateway_virtual',
  PHYSICAL = 'gateway.gateway_physical',
}

export enum EApiPackageSlug {
  PAY_AS_YOU_GO = 'plan_pro.api_package_pay_as_you_go',
  M5 = 'plan_pro.api_package_5m',
  M10 = 'plan_pro.api_package_10m',
  M20 = 'plan_pro.api_package_20m',
  M50 = 'plan_pro.api_package_50m',
}

export enum ETermLength {
  MONTH = 'month',
  YEAR = 'year',
}

export interface ICalculation {
  slug: string
  quantity?: number
  unitPrice?: number
  fixedPrice?: number
  overagePrice?: number
}

export const ProviderDetails = {
  [EPlanProvider.MAPPED]: {
    name: 'Mapped',
    manageLink: '/settings',
  },
  [EPlanProvider.AZURE]: {
    name: 'Azure Marketplace',
    manageLink: 'https://azuremarketplace.microsoft.com/',
  },
}

export const ProductDetails = {
  [EPlanSlug.STARTER]: {
    title: 'Starter',
    description:
      'The Starter plan will cover use of the API only, making it easy for individual application developers to build solutions using the Mapped API.',
  },
  [EPlanSlug.BASIC]: {
    title: 'Basic',
    description:
      'The BASIC plan covers normalization and retention of data originating from cloud-based sources with APIs.',
  },
  [EPlanSlug.PRO]: {
    title: 'Pro',
    description:
      'The PRO plan covers the discovery, extraction, normalization, and retention of data originating from on-prem building systems with legacy protocols, and cloud-based systems with APIs.',
  },
  [EGatewaySlug.VIRTUAL]: {
    title: 'Virtual',
  },
  [EGatewaySlug.PHYSICAL]: {
    title: 'Physical',
  },
}
