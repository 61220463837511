import { getMappedTheme } from '@mapped/rivet/dist/mapped/theme'
import { ThemeProvider } from '@mapped/rivet/dist/mui/styles'
import { FunctionComponent } from 'react'
import { CookiesProvider as XCookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SplitFactoryProvider } from '../lib/split'
import { ApolloContextProvider } from './apollo'
import { BillingContextProvider } from './billing'
import { ModalsContextProvider } from './modals'
import { NotificationsContextProvider } from './notifications'
import { ServicesContextProvider } from './services'
import { ThingsContextProvider } from './things'
import { UserContextProvider } from './user'

const CookiesProvider: any = XCookiesProvider
const queryClient = new QueryClient()

export const AppProviders: FunctionComponent = ({ children }) => {
  return (
    <CookiesProvider>
      <ServicesContextProvider>
        <UserContextProvider>
          <ApolloContextProvider>
            <SplitFactoryProvider>
              <ThemeProvider theme={getMappedTheme()}>
                <QueryClientProvider client={queryClient}>
                  <ThingsContextProvider>
                    <ModalsContextProvider>
                      <BillingContextProvider>
                        <NotificationsContextProvider>
                          {children}
                        </NotificationsContextProvider>
                      </BillingContextProvider>
                    </ModalsContextProvider>
                  </ThingsContextProvider>
                </QueryClientProvider>
              </ThemeProvider>
            </SplitFactoryProvider>
          </ApolloContextProvider>
        </UserContextProvider>
      </ServicesContextProvider>
    </CookiesProvider>
  )
}
