import { styled } from '@mapped/rivet/dist/mui/styles'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { isMobileOnly } from 'react-device-detect'
import SVG from 'react-inlinesvg'

export const MobileDeviceWarning: FunctionComponent = () => {
  const { pathname } = useRouter()

  const notAllowedPages = [
    '/onboarding',
    '/new-building',
    '/developer-onboarding',
    '/li-developer-onboarding',
    '/callback',
    '/callback/azure',
  ]

  if (!isMobileOnly || notAllowedPages.includes(pathname)) {
    return null
  }

  return (
    <Container>
      <Content>
        <Logo>
          <SVG src="/img/mapped-logo-wide.svg" />
        </Logo>

        <Text>
          The Mapped console is available at{' '}
          <a href="https://app.mapped.com">app.mapped.com</a> on your tablet or
          desktop.
        </Text>

        <Text style={{ marginTop: 30 }}>
          Check it out there for the best experience.
        </Text>

        <Text style={{ fontSize: 16, lineHeight: '24px' }}>
          Learn more at
          <br />
          <a href="https://mapped.com">
            <strong>mapped.com</strong>
          </a>
        </Text>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  background-image: url('/img/auth-background.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: bottom right;
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px;
`

const Logo = styled.div`
  max-width: 211px;
  height: 64px;

  svg {
    width: 100%;
    height: 100%;

    #lettering,
    g[fill='#FFFFFF'] {
      fill: ${(props) => props.theme.palette.text.primary};
    }
  }
`

const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 60px;
  max-width: 250px;

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.main};

    &:hover {
      text-decoration: underline;
    }
  }
`
