import Factory from '@mapped/rivet/node_modules/react-mapbox-gl'
import {
  FactoryParameters,
  Props,
} from '@mapped/rivet/node_modules/react-mapbox-gl/lib/map'
import { FunctionComponent, useRef } from 'react'
import { Services } from '../../services'

export const MapboxView: FunctionComponent<
  Partial<FactoryParameters> & Props
> = (props) => {
  const { current: ViewComponent } = useRef(
    Factory({
      ...props,
      accessToken: Services.mapbox.token,
    })
  )

  return <ViewComponent {...props} />
}
