import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../contexts/user'
import { Services } from '../../services'

export function SplitClientFactory(orgId?: string) {
  return SplitSdk({
    core: {
      authorizationKey: Services.split.api_key,
      key: orgId ?? 'loading',
      trafficType: 'org',
    },
  })
}

export const SplitFactoryProvider: FunctionComponent = ({ children }) => {
  const { organization } = useContext(UserContext)
  const [factory, setFactory] = useState(SplitClientFactory(organization?.id!))

  useEffect(() => {
    setFactory(SplitClientFactory(organization?.id!))
  }, [organization?.id])

  return (
    <SplitFactory factory={factory} updateOnSdkReady={true}>
      <>{children}</>
    </SplitFactory>
  )
}

// const ClientDataChecker: FunctionComponent = ({ children }) => {
//   const client = useClient()
//   const treatments = client?.getTreatments(Object.keys(EFeature)) ?? {}

//   for (const t in treatments) {
//     if (treatments[t] === 'control' && Services.app.env !== 'Local') {
//       return <></>
//     }
//   }

//   return <>{children}</>
// }
